/********** Template CSS **********/
:root {
    --primary: #4761FF;
    --secondary: #555555;
    --light: #F1F3FA;
    --dark: #1C2035;
}


 
  #da {
    position: relative;
  }
  
  #da:hover + #cc,
  #cc:hover {
    display: block;
  }
  
  #cc {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
  }
  
  #cc a {
    display: block;
    background-color: #f9f9f9;
    color: #333;
    text-decoration: none;
    padding: 5px 20px;
    margin-left: 200px;
    width: 280px;
    margin-top:-7px;
  }
  
  #cc a:hover {
    background-color: #e9e9e9;
  }
  
   
   

  

.back-to-top {
    position: fixed;
    display: none;
    right: 30px;
    bottom: 30px;
    z-index: 99;
}

.fw-medium {
    font-weight: 500;
}


/*** Spinner ***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}


/*** Button ***/
.btn {
    transition: .5s;
    font-weight: 500;
}

.btn-primary,
.btn-outline-primary:hover {
    color: var(--light);
}

.btn-secondary,
.btn-outline-secondary:hover {
    color: var(--dark);
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
}


/*** Navbar ***/
.sticky-top {
    top: -100px;
    transition: .5s;
}

.navbar .navbar-nav .nav-link {
    margin-right: 35px;
    padding: 25px 0;
    color: var(--dark);
    font-size: 18px;
    font-weight: 500;
    outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
    color: var(--primary);
}

.navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
}




.swing {
  animation: swing ease-in-out 1s infinite alternate;
  transform-origin: center -20px;
  float:left;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
}
.swing img {
  border: 5px solid #f8f8f8;
  display: block;
}
.swing:after{
  content: '';
  position: absolute;  
  width: 20px; height: 20px;  
  border: 1px solid #999;
  top: -10px; left: 50%;
  z-index: 0;
  border-bottom: none;
  border-right: none;
  transform: rotate(45deg);
}
/* nail */
.swing:before{
  content: '';
  position: absolute;
  width: 5px; height: 5px;
  top: -14px;left: 54%;
  z-index: 5;
  border-radius: 50% 50%;
  background: #000;
}

@keyframes swing {
  0% { transform: rotate(3deg); }
  100% { transform: rotate(-3deg); }
}

@media (max-width: 991.98px) {
    .navbar .navbar-nav .nav-link  {
        margin-right: 0;
        padding: 10px 0;
    }

    .navbar .navbar-nav {
        margin-top: 15px;
        border-top: 1px solid #EEEEEE;
    }
}

@media (min-width: 992px) {
    .navbar .nav-item .dropdown-menu {
        display: block;
        border: none;
        margin-top: 0;
        top: 150%;
        opacity: 0;
        visibility: hidden;
        transition: .5s;
    }

    .navbar .nav-item:hover .dropdown-menu {
        top: 100%;
        visibility: visible;
        transition: .5s;
        opacity: 1;
    }
}




.up-down{
    animation: up-down linear 4s;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
    -webkit-animation: up-down linear 4s;
    -webkit-animation-iteration-count: infinite;
    -webkit-transform-origin: 50% 50%;
    -moz-animation: up-down linear 4s;
    -moz-animation-iteration-count: infinite;
    -moz-transform-origin: 50% 50%;
    -o-animation: up-down linear 4s;
    -o-animation-iteration-count: infinite;
    -o-transform-origin: 50% 50%;
    -ms-animation: up-down linear 4s;
    -ms-animation-iteration-count: infinite;
    -ms-transform-origin: 50% 50%;
  }
  
  @keyframes up-down{
    0% {
      transform:  translate(1px,20px)  ;
    }
    24% {
      transform:  translate(1px,30px)  ;
    }
    50% {
      transform:  translate(1px,12px)  ;
    }
    74% {
      transform:  translate(1px,22px)  ;
    }
    100% {
      transform:  translate(1px,22px)  ;
    }
  }
  
  @-moz-keyframes up-down{
    0% {
      -moz-transform:  translate(1px,20px)  ;
    }
    24% {
      -moz-transform:  translate(1px,30px)  ;
    }
    50% {
      -moz-transform:  translate(1px,12px)  ;
    }
    74% {
      -moz-transform:  translate(1px,22px)  ;
    }
    100% {
      -moz-transform:  translate(1px,22px)  ;
    }
  }
  
  @-webkit-keyframes up-down {
    0% {
      -webkit-transform:  translate(1px,20px)  ;
    }
    24% {
      -webkit-transform:  translate(1px,30px)  ;
    }
    50% {
      -webkit-transform:  translate(1px,12px)  ;
    }
    74% {
      -webkit-transform:  translate(1px,22px)  ;
    }
    100% {
      -webkit-transform:  translate(1px,22px)  ;
    }
  }
  
  @-o-keyframes up-down {
    0% {
      -o-transform:  translate(1px,20px)  ;
    }
    24% {
      -o-transform:  translate(1px,30px)  ;
    }
    50% {
      -o-transform:  translate(1px,12px)  ;
    }
    74% {
      -o-transform:  translate(1px,22px)  ;
    }
    100% {
      -o-transform:  translate(1px,22px)  ;
    }
  }
  
  @-ms-keyframes up-down {
    0% {
      -ms-transform:  translate(1px,20px)  ;
    }
    24% {
      -ms-transform:  translate(1px,30px)  ;
    }
    50% {
      -ms-transform:  translate(1px,12px)  ;
    }
    74% {
      -ms-transform:  translate(1px,22px)  ;
    }
    100% {
      -ms-transform:  translate(1px,22px)  ;
    }
  }
/*** Header ***/
.carousel-caption {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, .1);
    z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
    width: 15%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 3.5rem;
    background-color: var(--primary);
    border: 15px solid var(--primary);
    margin-top:170px;
}
.dropdown-item:hover{
    color:#ffffff;
    background-color: #2054ae;
}
#cc .dropdown-item:hover{
    color:#ffffff;
    background-color: #2054ae;
}

.blockquote-custom {
    position: relative;
    font-size: 1.1rem;
  }

  
  .blockquote-custom-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -25px;
    left: 50px;
  }

  .jeen:hover{
    background-color: #2054ae;
    padding:20px;
    color:#ffffff;
}
.text3 {
    
    
  color: black;
  
  }

  
.jeen:hover h3{
    background-color: #2054ae;
    color:#ffffff;
}
#aa1{
    background-color: #ccc;
    color : #000;
    padding : 15px 15px;


}

#gggg {
    display: inline-block;
    position: relative;
    padding-bottom: 3px;
    margin-right: 10px;
    width: 100%;
    margin-top: 20px;
  }

  
  #gggg:after {
    content: '';
    display: block;
    height: 3px;
    width: 0px;
    background: transparent;
    width: 10%;
    background: #2054ae;
  }
#rrrr{
    gap:50px;
}
.nav-pills li.active a {
    background-color: #2054ae !important;
    color: #fff !important;
}
.nav-pills li:hover {
    background-color: #2054ae !important;
    color: #fff !important;
}

.thank-you-message {
    font-size: 18px;
    color: #ffffff;
    margin-top: 20px;
    background-color: green;
    padding:10px;
    border-radius: 10px;
}



#section1{
    height: 50vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    /* border: solid 1px #ffffff6b; */
    border-bottom-left-radius: 80px 80px;
}

.feedbacks{
    background-color: rgba(255, 255, 255, 0.541)!important;
    position: relative;
    display: flex;
    /* align-self: center; */
    width: 70%;
    /* height: 3rem; */
    /* margin-top: 4rem;
    left: 20%;
    right: 20%; */
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
}


/*--------BOLD FONT FOR THE TITTLE/BUTTON-------*/



.content .content-overlay {
    background: rgba(0,0,0,0.7);
    position: absolute;
    height: 99%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
  }
  
  .content:hover .content-overlay{
    opacity: 1;
  }
  
  .content-image{
    width: 100%;
  }
  
  .content-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
  
  .content:hover .content-details{
    top: 50%;
    left: 50%;
    opacity: 1;
  }
  
  .content-details h3{
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.15em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
  }
  
  .content-details p{
    color: #fff;
    font-size: 0.8em;
  }


  .effect-1 {
    position: relative;
}
.effect-1::before, .effect-1::after {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 15px;
    left: 10px;
    width: 50%;
    top: 80%;
    max-width: 300px;
    background: #aaa;
    box-shadow: 0 15px 10px #aaa;
    transform: rotate(-3deg);
}
.effect-1::after {
    transform: rotate(3deg);
    right: 10px;
    left: auto;
}
  
.bord{
    position: relative;
    color:#FFFFFF;
 
    span.border_btm {
      &:after {
        display: block;
        content: "";
        
        
       
      }
    
    }
    &:before {
      display: block;
      content: "";
      width: 30px;
      height: 30px;
      position: absolute;
      top: -10px;
      left: -10px;
      border-top: 3px solid #2054ae;
      border-left: 3px solid #2054ae;
    }
  
  }

  .immm{
 
        &.smooth {
          @include transition(all .5s ease-in-out);
        }
        &:hover {
          -webkit-filter: grayscale(100%);
                  filter: grayscale(100%);
        }
      
  }

  
  .input-field {
    border-radius: 25px;
    padding: 12px 20px;
}

.submit-btn {
    border-radius: 25px;
    padding: 10px 30px;
    font-weight: bold;
    transition: all 0.3s ease;
}

.submit-btn:hover {
    background-color: #2d1b49;
    border-color: #2d1b49;
}

@media (max-width: 768px) {
    #header-carousel .carousel-item {
        position: relative;
        min-height: 450px;
    }
   
  #se
  {
    min-width: fit-content;
  }
  
    
    #cc a {
        display: block;
        background-color: #f9f9f9;
        color: #333;
        text-decoration: none;
        padding: 5px 20px;
        margin-left: 100px;
        margin-top:-7px;
      }
    
    #header-carousel .carousel-item img {
        position: absolute;
        width: 50%;
        height: 100%;
        /* object-fit: cover; */
    }
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 3.5rem;
        background-color: var(--primary);
        border: 15px solid var(--primary);
        margin-top:300px;
    }
    .doop p {
        font-size: 2px;
    }
    .doop a{
        font-size: 10px;
    }
    .doop{
        margin-top: 90px;
    }

}

.page-header {
    /* background: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), url(../img/carousel-1.jpg) center center no-repeat; */
    background-size: cover;
}

.page-header .breadcrumb-item+.breadcrumb-item::before {
    color: var(--light);
}

.page-header .breadcrumb-item,
.page-header .breadcrumb-item a {
    font-size: 18px;
    color: var(--light);
}


/*** Features ***/
.feature-row {
    box-shadow: 0 0 45px rgba(0, 0, 0, .08);
}

.feature-item {
    border-color: rgba(0, 0, 0, .03) !important;
}


/*** About ***/
.about {
    /* background: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), url(../img/about.jpg) left center no-repeat; */
    background-size: cover;
}

.btn-play {
    position: relative;
    display: block;
    box-sizing: content-box;
    width: 36px;
    height: 46px;
    border-radius: 100%;
    border: none;
    outline: none !important;
    padding: 18px 20px 20px 28px;
    background: var(--primary);
}

.btn-play:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 90px;
    height: 90px;
    background: var(--primary);
    border-radius: 100%;
    animation: pulse-border 1500ms ease-out infinite;
}

.btn-play:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 90px;
    height: 90px;
    background: var(--primary);
    border-radius: 100%;
    transition: all 200ms;
}

.btn-play span {
    display: block;
    position: relative;
    z-index: 3;
    width: 0;
    height: 0;
    left: 3px;
    border-left: 30px solid #FFFFFF;
    border-top: 18px solid transparent;
    border-bottom: 18px solid transparent;
}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(2);
        opacity: 0;
    }
}

.modal-video .modal-dialog {
    position: relative;
    max-width: 800px;
    margin: 60px auto 0 auto;
}

.modal-video .modal-body {
    position: relative;
    padding: 0px;
}

.modal-video .close {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 0px;
    top: -30px;
    z-index: 999;
    font-size: 30px;
    font-weight: normal;
    color: #FFFFFF;
    background: #000000;
    opacity: 1;
}


/*** Service ***/
.service-item .service-text {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0 0 45px rgba(0, 0, 0, .06);
    transition: .5s;
    z-index: 2;
}

.service-item:hover .service-text {
    top: -1.5rem;
}

.service-item .service-text h5 {
    transition: .5s;
}

.service-item:hover .service-text h5 {
    color: var(--primary);
}

.service-item .service-btn {
    position: absolute;
    
    bottom: 0;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: 0 0 45px rgba(0, 0, 0, .07);
    transition: .5s;
    z-index: 1;
    background-color: #1a73e9;
}

.service-item:hover .service-btn{
    bottom: -6.2rem;
    padding: 10px;
    /* font-size:14px; */
}
.service-item:hover .dood{
    margin-bottom:0rem;
}

.gh{
    transition: 1s;
    
}
.gh:hover{
    background-color: #1a73e9;
    color: white; /* "white" instead of "#fff" */
    /* padding: 10px; */
    border-radius: 8px;
    transform: scale(1.1);
		background: #1a73e9;
		z-index: 1;
		box-shadow: 2px 2px 2px #000;
}
.gh:hover .nr{
    background-color: #fff;
}

.gh:hover h5{
    color: #fff;
}
/*** Project ***/
.project-item {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 45px rgba(0, 0, 0, .07);
}

.project-item .project-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .5);
    opacity: 0;
    padding-top: 60px;
    transition: .5s;
}

.project-item:hover .project-overlay {
    opacity: 1;
    padding-top: 0;
}

.project-carousel .owl-nav {
    position: absolute;
    top: -100px;
    right: 0;
    display: flex;
}

.project-carousel .owl-nav .owl-prev,
.project-carousel .owl-nav .owl-next {
    margin-left: 15px;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondary);
    border-radius: 55px;
    box-shadow: 0 0 45px rgba(0, 0, 0, .15);
    font-size: 25px;
    transition: .5s;
}

.project-carousel .owl-nav .owl-prev:hover,
.project-carousel .owl-nav .owl-next:hover {
    background: var(--primary);
    color: #FFFFFF;
}

@media (max-width: 768px) {
    .project-carousel .owl-nav {
        top: -70px;
        right: auto;
        left: 50%;
        transform: translateX(-50%);
    }


   
  



    .project-carousel .owl-nav .owl-prev,
    .project-carousel .owl-nav .owl-next {
        margin: 0 7px;
        width: 45px;
        height: 45px;
        font-size: 20px;
    }
}




/*** Team ***/
.team-item {
    position: relative;
    text-align: center;
    box-shadow: 0 0 45px rgba(0, 0, 0, .07);
}

.team-item .team-social {
    position: absolute;
    padding: 0;
    top: 15px;
    left: 0;
    overflow: hidden;
}

.team-item .team-social li {
    list-style-type: none;
    margin-bottom: 10px;
    margin-left: -50px;
    opacity: 0;
    transition: .5s;
}

.team-item:hover .team-social li {
    margin-left: 15px;
    opacity: 1;
}

.team-item .team-social li .btn {
    background: #FFFFFF;
    color: var(--primary);
    border-radius: 40px;
    transition: .5s;
}

.team-item .team-social li .btn:hover {
    color: #FFFFFF;
    background: var(--primary);
}

.team-item .team-social li:nth-child(1) {
    transition-delay: .1s;
}

.team-item .team-social li:nth-child(2) {
    transition-delay: .2s;
}

.team-item .team-social li:nth-child(3) {
    transition-delay: .3s;
}

.team-item .team-social li:nth-child(4) {
    transition-delay: .4s;
}

.team-item .team-social li:nth-child(5) {
    transition-delay: .5s;
}


/*** Testimonial ***/
.testimonial-item {
    box-shadow: 0 0 45px rgba(0, 0, 0, .07);
    transition: .5s;
}

.owl-item .testimonial-item,
.testimonial-item * {
    transition: .5s;
}

.owl-item.center .testimonial-item,
.testimonial-item:hover {
    background: var(--primary);
}

.owl-item.center .testimonial-item *,
.testimonial-item:hover * {
    color: #FFFFFF !important;
}

.testimonial-item img {
    width: 100px !important;
    height: 100px !important;
    border-radius: 100px;
}

.testimonial-carousel .owl-nav {
    position: absolute;
    top: -100px;
    right: 0;
    display: flex;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
    margin-left: 15px;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondary);
    border-radius: 55px;
    box-shadow: 0 0 45px rgba(0, 0, 0, .15);
    font-size: 25px;
    transition: .5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
    background: var(--primary);
    color: #FFFFFF;
}

@media (max-width: 768px) {
    .testimonial-carousel .owl-nav {
        top: -70px;
        right: auto;
        left: 50%;
        transform: translateX(-50%);
    }

    .testimonial-carousel .owl-nav .owl-prev,
    .testimonial-carousel .owl-nav .owl-next {
        margin: 0 7px;
        width: 45px;
        height: 45px;
        font-size: 20px;
    }
}


/*** Footer ***/
.footer {
    color: #B0B9AE;
}
@keyframes shake {
    0% { transform: translateX(0); }
    50% { transform: translateX(20px); }
    100% { transform: translateX(0); }
  }
  
  .shake {
    animation: shake 2s ease-in-out infinite;
  }
  
.footer .btn.btn-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: #B0B9AE;
    font-weight: normal;
    text-transform: capitalize;
    transition: .3s;
}
.footer .btn.btn-link{
    color:#fff;
    font-size: 20px;
}

.footer .btn.btn-link::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 10px;
}

/* .footer .btn.btn-link:hover {
    color: var(--light);
    letter-spacing: 1px;
    box-shadow: none;
} */
.footer .btn.btn-link.active {
    color: purple;
    
}
.footer .btn.btn-link.inactive {
    color: #ffffff;
}
.footer .btn.btn-link.inactive:hover {
    color: #ffffff;
    letter-spacing: 1px;
    box-shadow: none;
}
.footer .btn.btn-link.active:hover {
    color: purple;
    letter-spacing: 1px;
    box-shadow: none;
}

.copyright {
    color: #B0B9AE;
}

.copyright {
    background: #111111;
}

.copyright a:hover {
    color: var(--primary) !important;
}